body {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #ff008c 0%, rgb(211, 9, 225) 100%);
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.row{
  height: 50vh;
  justify-content: center;
  align-items: center;
}

.col > div {
  background: white;
  border-radius: 30px;
  width: 250px;
  height: 250px;
  margin: auto auto;

  position: relative;

  overflow: hidden;
}

.col > div.scene {
  width: 400px;
  height: 300px;
  overflow: hidden;

}

.col > div.scene > div{
  margin-top: -8px;
  margin-left: -8px;
}

.loading-container{
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container div {
  background: white;
  border-radius: 30px;
  width: 150px;
  height: 150px;
  margin: auto auto;

  position: relative;
}

.item {
  width: 100%;
  overflow: visible;
  stroke: #660000;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

/* SWIPE CONTAINER */
.swipe-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.swipe-container img {
  position: absolute;
  max-width: 100%;
}

.next{
  position: absolute;
  /*background-color: aqua;*/

  top: 0;
  right: 0;
  bottom: 0;

  width: 25%;

  z-index: 2;
}

.previous{
  position: absolute;
  /*background-color: coral;*/

  top: 0;
  left: 0;
  bottom: 0;

  width: 25%;

  z-index: 2;
}

.previous::after,
.next::after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  pointer-events: none;

  opacity: 0.5;
}

.next::after{
  background: linear-gradient(to right, transparent, #ccc);
}

.previous::after{
  background: linear-gradient(to left, transparent, #ccc);
}

/* MENU */
nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #ff6cb9;
}

button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0px 25px 0 45px;
  position: absolute;
  top: 90px;
  width: 230px;
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}